/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { is } from 'ramda'
import { bool, number, oneOfType, string } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import NumeratorDenominator from '~/components/NumeratorDenominator'
import formatPrice from '~/utilities/formatPrice'
import formatData from '~/utilities/formatData'

const DISPLAY_NAME = 'PricingSliderDefinitionList'

const QUERY = graphql`
  query {
    pricing: contentfulPricing {
      ...Pricing
    }
  }
`

const PriceDataDefinitionListRow = ({ children }) => (
  <div
    sx={{
      display: 'flex',
      gap: 1,
    }}
  >
    {children}
  </div>
)

const PriceDataDefinitionListTitle = ({ children }) => (
  <dt>
    {children}
    :
  </dt>
)

const PriceDataDefinitionListData = ({ children }) => (
  <dd
    sx={{
      margin: 0,
      color: 'brand',
    }}
  >
    {children}
  </dd>
)

const PROP_TYPES = {
  competitorPrice: oneOfType([string, number]),
  freeIsSelected: bool,
  price: oneOfType([string, number]),
  requests: oneOfType([string, number]),
  storage: oneOfType([string, number]),
}

const gbToTb = dataAmount => {
  const { length } = dataAmount.toString()
  if (length >= 4) {
    const tb = dataAmount / 1000
    return `${tb} TB`
  }
  return formatData(dataAmount)
}

const Component = ({
  competitorPrice,
  freeIsSelected = false,
  price,
  requests,
  storage,
}) => {
  const { pricing } = useStaticQuery(QUERY)
  const {
    paidPlanAdditionalRequestsPackageGb,
    paidPlanAdditionalRequestsPackagePrice,
  } = pricing

  return (
    <dl sx={{ variant: 'text.heading2' }}>
      <PriceDataDefinitionListRow>
        <PriceDataDefinitionListTitle>
          Storage
        </PriceDataDefinitionListTitle>
        <PriceDataDefinitionListData>
          {is(Number, storage) ? `${storage} TB` : storage}
        </PriceDataDefinitionListData>
      </PriceDataDefinitionListRow>
      <PriceDataDefinitionListRow>
        <PriceDataDefinitionListTitle>
          Retrievals
        </PriceDataDefinitionListTitle>
        <PriceDataDefinitionListData>
          {is(Number, requests) ? (
            <NumeratorDenominator
              numerator={gbToTb(requests)}
              denominator="month"
              variant="text.heading2"
            />
          ) : requests}
          {!freeIsSelected && (
            <Text
              sx={{
                variant: 'text.finePrint',
                display: ['none', 'inline'],
              }}
            >
              &ensp;
              (additional
              {' '}
              <NumeratorDenominator
                numerator={formatPrice(paidPlanAdditionalRequestsPackagePrice)}
                denominator={formatData(paidPlanAdditionalRequestsPackageGb, 'gb')}
                smallDenominator={false}
              />
              )
            </Text>
          )}
        </PriceDataDefinitionListData>
      </PriceDataDefinitionListRow>
      <PriceDataDefinitionListRow>
        <PriceDataDefinitionListTitle>
          Price
        </PriceDataDefinitionListTitle>
        <PriceDataDefinitionListData>
          {is(Number, price) ? (
            <NumeratorDenominator
              numerator={formatPrice(price)}
              denominator="month"
              variant="text.heading2"
            />
          ) : price}
          {competitorPrice && (
            <Text
              sx={{
                variant: 'text.finePrint',
                display: ['none', 'inline'],
              }}
            >
              &ensp;
              (traditional cloud storage:
              {' '}
              {is(Number, competitorPrice) ? (
                <NumeratorDenominator
                  numerator={formatPrice(competitorPrice)}
                  denominator="month"
                  smallDenominator={false}
                />
              ) : competitorPrice}
              *)
            </Text>
          )}
        </PriceDataDefinitionListData>
      </PriceDataDefinitionListRow>
    </dl>
  )
}

Component.propTypes = PROP_TYPES
Component.displayName = DISPLAY_NAME

export default Component
