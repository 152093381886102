/** @jsx jsx */
import { string } from 'prop-types'
import { Box, Container, Heading, jsx } from 'theme-ui'

import IntroTransition from '~/components/IntroTransition'
import PricingSlider from '~/components/PricingSlider'
import Section from '~/components/Section'
import renderRichText from '~/content/renderRichText'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'PricingSliderSection'

const PROP_TYPES = {
  elementId: string,
  heading: string,
  body: ContentfulRichTextPropType,
}

const Component = ({ elementId, heading, body }) => (
  <Section id={elementId}>
    <Container variant="containerSmall">
      <IntroTransition>
        <Box sx={{ textAlign: 'center' }}>
          {heading && (
            <Heading
              sx={{
                variant: 'text.heading1',
                marginBottom: 0.5,
              }}
            >
              {heading}
            </Heading>
          )}
          {body && (
            <div sx={{ marginBottom: 4 }}>
              {renderRichText(body, { style: 'prospect' })}
            </div>
          )}
          <PricingSlider />
        </Box>
      </IntroTransition>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
