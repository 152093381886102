/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { useState } from 'react'
import { is, range } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Slider from '~/components/Slider'
import PriceDataDefinitionList from './PricingSliderDefinitionList'
import formatPrice from '~/utilities/formatPrice'
import formatData from '~/utilities/formatData'
import NumeratorDenominator from '~/components/NumeratorDenominator'

const DISPLAY_NAME = 'PricingSlider'

const QUERY = graphql`
  query {
    pricing: contentfulPricing {
      ...Pricing
    }
  }
`

const COMPETITOR_PER_TB_PRICE = 4.995

const NON_NUMBER_PRICES = {
  free: 'FREE',
}

const NON_NUMBER_COMPETITOR_PRICES = {
  free: null,
}

const VALUES = ['free', ...range(1, 21)]

const Component = () => {
  const { pricing } = useStaticQuery(QUERY)
  const {
    freePlanBaseRequestsGb,
    freePlanStorageGb,
    paidPlanAdditionalRequestsPackageGb,
    paidPlanAdditionalRequestsPackagePrice,
    paidPlanAdditionalRequestsPerTBStorageGb,
    paidPlanBaseRequestsGb,
    paidPlanPricePerTbMonthly,
  } = pricing

  const calcRequestsGb = dataAmount => {
    const additionalGb = paidPlanAdditionalRequestsPerTBStorageGb * (dataAmount - 1)
    return paidPlanBaseRequestsGb + additionalGb
  }

  const nonNumberRequests = {
    free: freePlanBaseRequestsGb,
  }

  const nonNumberStorage = {
    free: `${freePlanStorageGb} GB`,
  }

  const [storageAmount, setStorageAmount] = useState<string | number>(1)
  const freeIsSelected = storageAmount === 'free'
  const handleValueChange = setStorageAmount

  const price = is(Number, storageAmount)
    ? paidPlanPricePerTbMonthly * storageAmount
    : NON_NUMBER_PRICES[storageAmount]

  const competitorPrice = is(Number, storageAmount)
    ? COMPETITOR_PER_TB_PRICE * storageAmount
    : NON_NUMBER_COMPETITOR_PRICES[storageAmount]

  const storage = is(Number, storageAmount)
    ? storageAmount
    : nonNumberStorage[storageAmount]

  const requests = is(Number, storageAmount)
    ? calcRequestsGb(storageAmount)
    : nonNumberRequests[storageAmount]

  return (
    <div sx={{ textAlign: 'left' }}>
      <PriceDataDefinitionList
        {...{
          competitorPrice,
          freeIsSelected,
          price,
          requests,
          storage,
        }}
      />
      <Slider
        values={VALUES}
        onValueChange={handleValueChange}
        value={storageAmount}
        leftLabel="less"
        rightLabel="more"
      />
      <div
        sx={{
          display: [null, 'none'],
          marginY: 2,
        }}
      >
        {!freeIsSelected && (
          <Text as="div">
            Additional retrievals
            {' '}
            <NumeratorDenominator
              numerator={formatPrice(paidPlanAdditionalRequestsPackagePrice)}
              denominator={formatData(paidPlanAdditionalRequestsPackageGb, 'gb')}
              smallDenominator={false}
            />
          </Text>
        )}
        {competitorPrice && (
          <Text as="div">
            Equivalent traditional cloud storage:
            {' '}
            {is(Number, competitorPrice) ? (
              <NumeratorDenominator
                numerator={formatPrice(competitorPrice)}
                denominator="month"
                smallDenominator={false}
              />
            ) : competitorPrice}
            *
          </Text>
        )}
      </div>
      <Text
        as="div"
        sx={{
          variant: 'text.finePrint',
          textAlign: 'left',
          marginTop: [null, 4],
        }}
      >
        * Based on Google One Premium plan pricing as of 7 January 2022.
      </Text>
    </div>
  )
}

Component.displayName = DISPLAY_NAME

export default Component
