/** @jsx jsx */
import { any, arrayOf, func, number, string } from 'prop-types'
import { useEffect, useState } from 'react'
import { jsx, Button } from 'theme-ui'

const DISPLAY_NAME = 'Slider'

const THUMB_STYLES = {
  width: '2em',
  height: '2em',
  borderRadius: '50%',
  backgroundColor: 'brand',
  marginTop: 'calc(1em * 0.5 - 2em * 0.5)',
  cursor: 'pointer',
  boxShadow: 'subtle',
}

const TRACK_STYLES = {
  height: '1em',
  borderRadius: '0.5em',
  background: 'linear-gradient(#3ee5dd,#3ee5dd) 0/var(--sx) 100% no-repeat, #2C3245',
}

const STYLES = {
  overscrollBehavior: 'contain',
  height: '1em',
  backgroundColor: 'backgroundLight',
  WebkitAppearance: 'none',
  width: '100%',
  borderRadius: '0.5em',
  margin: '0.6em 0',
  '--range': 'calc(var(--max) - var(--min))',
  '--ratio': 'calc((var(--value) - var(--min)) / var(--range))',
  '--sx': 'calc(0.5 * 2em + var(--ratio) * (100% - 2em))',
  '::-webkit-slider-thumb': {
    ...THUMB_STYLES,
    WebkitAppearance: 'none',
    appearance: 'none',
  },
  '::-moz-range-thumb': THUMB_STYLES,
  '::-webkit-slider-runnable-track': TRACK_STYLES,
  '::-moz-range-track': TRACK_STYLES,
}

interface PropsInterface {
  leftLabel?: string,
  max?: number,
  min?: number,
  // eslint-disable-next-line no-unused-vars
  onValueChange?(value: any): void,
  rightLabel?: string,
  value?: any,
  values?: any[],
}

const PROP_TYPES = {
  leftLabel: string,
  max: number,
  min: number,
  onValueChange: func,
  rightLabel: string,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  // eslint-disable-next-line react/forbid-prop-types
  values: arrayOf(any),
}

const Component = ({
  max,
  min = 0,
  onValueChange,
  values,
  value: valueProp,
  leftLabel,
  rightLabel,
}: PropsInterface) => {
  const [currentIndex, setCurrentIndex] = useState(valueProp)
  const valuesMax = values?.length > 0 ? values.length - 1 : 0
  const computedMax = max ?? valuesMax

  const handleChange = event => {
    const index = parseInt(event.target.value, 10)
    setCurrentIndex(index)
  }

  const decreaseValue = () => {
    const newIndex = currentIndex - 1
    if (newIndex < 0) return
    setCurrentIndex(newIndex)
  }

  const increaseValue = () => {
    const newIndex = currentIndex + 1
    if (newIndex > computedMax) return
    setCurrentIndex(newIndex)
  }
  const handleDecreaseButtonClick = decreaseValue
  const handleIncreaseButtonClick = increaseValue

  useEffect(() => {
    const newCurrentIndex = values?.length ? values.indexOf(valueProp) : valueProp
    setCurrentIndex(newCurrentIndex)
  }, [valueProp, values])

  useEffect(() => {
    const newValue = values?.[currentIndex] || currentIndex
    onValueChange?.(newValue)
  }, [currentIndex, onValueChange, values])

  return (
    <div>
      <input
        type="range"
        min={min}
        max={computedMax}
        // @ts-expect-error
        sx={{
          '--value': currentIndex,
          '--min': min,
          '--max': computedMax,
          ...STYLES,
        }}
        onChange={handleChange}
        value={currentIndex}
      />
      {(leftLabel || rightLabel) && (
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 0.5,
          }}
        >
          <Button
            variant="padded"
            onClick={handleDecreaseButtonClick}
          >
            less storage
          </Button>
          <Button
            variant="padded"
            onClick={handleIncreaseButtonClick}
          >
            more storage
          </Button>
        </div>
      )}
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
